import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import PostCard from "../components/post-card"
import SEO from "../components/seo"
import { RiHome2Line } from "react-icons/ri"

// Components
import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  // const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${tag}`

  const posts = data.allMarkdownRemark.edges
  .filter(edge => !!edge.node.frontmatter.date)
  .map(edge =>
    <PostCard key={edge.node.id} data={edge.node} />
  )

  return (
    <Layout className="blog-page">
      <SEO
        title={tag + " | タグ"}
        description={tag}
        norobots={true}
      />
      <h1 className="taxonomy-header">{tagHeader}</h1>
      <div className="grids col-1 sm-2 lg-3">
          {posts}
      </div>

      <Link to="/tags/" className="taxonomy-list">タグリスト</Link>

      <div className="back-to-home">
        <Link to="/"><RiHome2Line />サイトトップへ</Link>
      </div>
      </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
query($tag: String) {
  allMarkdownRemark(
    limit: 2000
    sort: { fields: [frontmatter___date], order: DESC }
    filter: { frontmatter: { tags: { in: [$tag] } } }
  ) {
    totalCount
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY/MM/DD")
          slug
          title
          tags
          category
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 540, maxHeight: 304, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
}
`